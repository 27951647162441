<template>
  <a-tag :class="`caption-4 lg:caption-3 px-12 py-8 lg:p-16 mb-8 ${fill ? 'lg:w-full' : ''} ${variant === 'primary' ? 'bg-success-50 text-success-900': 'bg-neutral-100'}`">
    <slot />
  </a-tag>
</template>

<script>

export default {
  name: 'TagComponent',
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    fill: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
