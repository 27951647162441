<template>
  <a-tag
    :class="`caption-5 lg:caption-3 px-8 py-2 lg:px-12 lg:py-2 ${variant === 'new' ? 'bg-danger-600 text-white': ''} ${variant === 'hot' ? 'bg-warning-600 text-white': ''}
   `"
  >
    <slot />
  </a-tag>
</template>

<script>

export default {
  name: 'TagComponent',
  props: {
    variant: {
      type: String,
      default(){
        return 'new'
      } 
    },
  }
}
</script>


