<template>
  <div
    class="relative flex flex-col lg:flex-row px-16 py-20 lg:p-20 bg-neutral-100 mb-24 lg:mb-30 border border-solid border-neutral-300 cursor-pointer"
    @click="() => toggleModal(item)"
  >
    <div class="absolute top-[-12px]">
      <Badge v-if="item.tagNew" variant="new"> Mới </Badge>
      <Badge v-if="item.tagHot" variant="hot"> Hot </Badge>
    </div>
    <div
      class="flex justify-between pb-16 lg:pb-0 border-b-1 lg:border-b-0 lg:border-r-1 border-solid border-0 border-neutral-200 mr-16 lg:mr-20"
    >
      <div class="caption-3 lg:caption-2 lg:w-[344px] flex items-center">
        {{ item.industry?.name }}
      </div>
      <more-outlined class="lg:hidden w-20 h-20 text-20 hover:bg-neutral-200" />
    </div>
    <ul
      class="flex flex-col lg:flex-row gap-16 lg:gap-20 list-none mb-0 flex-1 p-0"
    >
      <li
        class="flex-1 py-16 lg:py-0 border-b-1 lg:border-b-0 border-solid border-0 border-neutral-200"
      >
        <div
          class="heading-2 lg:heading-1 text-neutral-300 mb-4 whitespace-nowrap"
        >
          Mức lương (Eur/tháng)
        </div>
        <div class="caption-3 whitespace-nowrap text-success-900">
          {{ item.firstYearSalary }}
          {{ item.secondYearSalary && `- ${item.secondYearSalary}` }}
          {{ item.period === 3 ? `- ${item.thirdYearSalary}` : "" }}
        </div>
      </li>

      <li class="flex items-center lg:block">
        <div
          class="heading-2 w-80 lg:w-auto lg:heading-1 text-neutral-300 mb-4 whitespace-nowrap"
        >
          Thời gian
        </div>
        <div class="typography-2 lg:typography-1 whitespace-nowrap">
          {{ item.period }} năm
        </div>
      </li>

      <li class="flex-1 flex items-center lg:block">
        <div
          class="heading-2 w-80 lg:w-auto lg:heading-1 text-neutral-300 mb-4 whitespace-nowrap"
        >
          Bang
        </div>
        <div class="typography-2 lg:typography-1 whitespace-nowrap">
          {{ item.state }}
        </div>
      </li>
      <li class="flex-1 flex items-center lg:block">
        <div
          class="heading-2 w-80 lg:w-auto lg:heading-1 text-neutral-300 mb-4 whitespace-nowrap"
        >
          Kỳ bay
        </div>
        <div class="typography-2 lg:typography-1 whitespace-nowrap">
          {{ item.fromDate && format(new Date(item.fromDate), "mm/yyyy") }} -
          {{ item.toDate && format(new Date(item.toDate), "mm/yyyy") }}
        </div>
      </li>
      <li class="hidden lg:flex items-center justify-center">
        <more-outlined class="w-20 h-20 text-20 hover:bg-neutral-200" />
      </li>
    </ul>
  </div>
</template>

<script>
import { MoreOutlined } from "@ant-design/icons-vue";
import { format } from "date-fns";
import Badge from "./badge";

export default {
  name: "ItemComponent",
  components: {
    MoreOutlined,
    Badge,
  },
  props: {
    imageUrl: {
      type: String,
      default() {
        return "";
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    toggleModal: {
      type: Function,
      default: () => {},
    },
  },
  data: function () {
    return {
      format,
      formState: {
        username: "",
        password: "",
        remember: "",
      },
    };
  },
  methods: {
    onFinishFailed() {},
  },
};
</script>
