import axios from 'axios'
import qs from 'qs'

export const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000,
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  switch (error.response.status) {
    case 401: {
      localStorage.removeItem('token')
    }
  }
  return Promise.reject(error);
});

const get = (url) => {
  return instance.get(url)
}

const post = (url, data) => {
  return instance.post(url, data)
}

export const login = (data) => {
  return post('/login', data)
}

export const getCategories = () => {
  return get('/industry')
}

export const getContracts = (filter) => {
  return get(`/contract?${qs.stringify(filter)}`)
}

export const getPeriods = () => {
  return get(`/period`)
}