import { createStore } from 'vuex'
// Create a new store instance.
export const store = createStore({
  state () {
    return {
      isLogged: false
    }
  },
  mutations: {
    toggleLogged (state, payload) {
      if (payload && payload.value) {
        state.isLogged =  payload.value
      } else {
        state.isLogged =  !state.isLogged
      }
    }
  }
})
