<template>
  <a-config-provider
    :theme="{
      hashed: false,
      token: {
        borderRadius: 0,
        colorPrimary: '#006851',
        fontFamily: 'Inter',
        fontWeight: 300,
      },
    }"
  >
    <Layout>
      <router-view />
    </Layout>
  </a-config-provider>
</template>

<script>
import Layout from './components/layout'

export default {
  name: 'App',
  components: {
    Layout,
  }
}
</script>

<style>
#app {
  font-family: Inter;
  font-weight: 300;
}
</style>
