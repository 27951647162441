import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import { createRouter, createWebHistory } from 'vue-router'

import App from './App';
import { store } from './store';
import routes from './router/index'
import './style/index.css'  

import 'ant-design-vue/dist/reset.css';


const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

const app = createApp(App);
app.use(router);
app.use(Antd);
app.use(store);


app.mount('#app');