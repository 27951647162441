<template>
  <a-layout class="min-h-screen">
    <a-layout-header
      class="sticky top-0 header z-10"
      style="background-color: white; padding: 0"
    >
      <div class="container mx-auto lg:px-0 px-16">
        <a-row
          align="center"
          justify="space-between"
        >
          <a-col class="">
            <sppan
              class="cursor-pointer lg:caption-1 caption-2"
              @click="handleClickLogo"
            >
              Du học nghề Đức
            </sppan>
          </a-col>
          <a-col flex="auto" />
          <a-col>
            <a-button
              v-if="isLogged"
              class="shadow-none bg-neutral-200 text-neutral-400 lg:w-160 font-semibold h-36"
              @click="handleLogout"
            >
              Đăng xuất
            </a-button>
            <a-button
              v-if="!isLogged"
              class="shadow-none lg:w-160 font-semibold h-36"
              type="primary"
              @click="handleLogin"
            >
              Đăng nhập
            </a-button>
          </a-col>
        </a-row>
      </div>
    </a-layout-header>
    <a-layout-content class="bg-neutral-200 flex-1">
      <slot />
    </a-layout-content>
    <a-layout-footer
      style="background-color: white"
      class="text-center"
    >
      <div class="container mx-auto">
        <span class="caption-2 lg:caption-1">
          Liên hệ với chúng tôi qua Zalo ngay!
        </span>
      </div>
    </a-layout-footer>
    <svg
      class="fixed bottom-12 right-12 cursor-pointer"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="handleClickZaloLogo"
    >
      <g filter="url(#filter0_d_1112_3037)">
        <g clip-path="url(#clip0_1112_3037)">
          <rect
            x="8"
            y="4"
            width="44"
            height="44.0002"
            rx="8.8"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 6V25.9309V25.9359V46V48H12H30.0195H30.0227H48H52V46V36.8872C52 36.8839 52 36.8806 52 36.8773V36L51.5971 36.9066C51.0813 37.4897 50.0138 38.3929 49.0609 39.0433C46.1056 41.0621 42.4625 42.2833 37.8068 42.8113C36.5796 42.9489 32.8594 42.9489 31.6707 42.8113C27.4442 42.3108 24.1423 41.2931 21.3962 39.6428C21.0385 39.4283 20.7303 39.2523 20.7083 39.2523C20.6863 39.2523 20.4166 39.3513 20.1029 39.4723C18.8262 39.9729 17.3789 40.2424 15.9645 40.2424C15.1721 40.2424 15.0895 40.2314 14.996 40.1324C14.9024 40.0334 14.9024 40.0224 15.0015 39.8959C16.2067 38.3612 16.6194 37.382 16.6194 36.0453C16.6194 35.4567 16.5259 35.2147 15.981 34.384C15.5188 33.6854 14.8199 32.2827 14.3961 31.2211C13.5926 29.1803 13.0643 26.9854 12.7837 24.455C12.6461 23.2613 12.6516 20.0599 12.7837 18.8442C13.2459 14.6855 14.4346 11.1925 16.3553 8.32657C17.3623 6.82484 18.8977 5.2681 20.3341 4.29445C20.5487 4.15143 20.7303 4.02491 20.7413 4.01391C20.7628 3.99244 18.9633 3.99779 18 4.01425V4H12H8V6Z"
            fill="#0068FF"
          />
          <path
            d="M37.1037 23.5861C37.1202 28.0088 37.1312 28.7349 37.2027 28.8394C37.3457 29.0649 37.6592 29.1419 38.3028 29.1199L38.8804 29.1034V23.7841V18.4593H37.9838H37.0872L37.1037 23.5861Z"
            fill="#0068FF"
          />
          <path
            d="M18.435 19.4499V20.33L21.3737 20.341L24.3179 20.3575L21.3957 23.9881C19.6897 26.1114 18.424 27.7342 18.3469 27.8992C18.2589 28.0917 18.1983 28.3283 18.1763 28.6198L18.1433 29.0599L18.3249 29.0929C18.424 29.1149 20.24 29.1314 22.3643 29.1259C25.2039 29.1259 26.2716 29.1094 26.4146 29.0599C26.5192 29.0214 26.6568 28.9114 26.7283 28.8068C26.8384 28.6418 26.8549 28.5538 26.8549 28.0532V27.4811H23.8061C22.1276 27.4811 20.7408 27.4646 20.7298 27.4371C20.7133 27.4151 21.0875 26.9145 21.5608 26.3205C24.1418 23.0915 26.5137 20.0825 26.6183 19.901C26.7503 19.6864 26.8329 19.3399 26.8714 18.8558L26.8934 18.5698H22.667H18.435V19.4499Z"
            fill="#0068FF"
          />
          <path
            d="M43.5757 20.9064C42.7613 21.0604 42.0238 21.4455 41.424 22.023C40.692 22.7327 40.2793 23.5743 40.1858 24.5644C40.0041 26.3577 40.8792 27.9695 42.4531 28.7451C43.185 29.1081 43.6363 29.2016 44.4948 29.1686C45.0121 29.1466 45.2377 29.1136 45.5404 29.0091C46.7291 28.5855 47.461 27.9419 47.9893 26.8473C48.3085 26.1872 48.4296 25.6811 48.4296 24.988C48.4296 23.7833 47.8737 22.5951 46.9657 21.847C45.9971 21.0494 44.7149 20.6918 43.5757 20.9064ZM44.88 22.6116C45.8486 22.8647 46.5695 23.6513 46.7401 24.6469C46.8611 25.3676 46.5915 26.1762 46.0302 26.7648C45.2927 27.5404 44.2196 27.7329 43.24 27.2708C42.354 26.8528 41.8257 26.0167 41.8257 25.0265C41.8257 23.4093 43.3391 22.2156 44.88 22.6116Z"
            fill="#0068FF"
          />
          <path
            d="M30.9419 20.9292C28.7846 21.2758 27.2657 23.1625 27.4253 25.3079C27.4693 25.924 27.5849 26.3255 27.8766 26.9251C28.0912 27.3542 28.2178 27.5302 28.6085 27.9208C29.1313 28.4489 29.5771 28.7294 30.254 28.9549C30.6337 29.0815 30.7658 29.098 31.5032 29.098C32.2571 29.098 32.3672 29.087 32.7744 28.9494C33.0166 28.8669 33.3688 28.7074 33.5504 28.5864L33.8861 28.3718L34.0127 28.6469C34.1998 29.0429 34.2988 29.0925 35.0032 29.12L35.6031 29.1475V25.1484V21.1547H34.7776H33.9521V21.4023C33.9521 21.5398 33.9411 21.6498 33.9356 21.6498C33.9246 21.6498 33.677 21.5288 33.3908 21.3858C33.1046 21.2373 32.7084 21.0777 32.5103 21.0282C32.0645 20.9182 31.3271 20.8687 30.9419 20.9292ZM32.0755 22.6455C32.4608 22.7335 32.934 23.003 33.2422 23.3111C34.1888 24.2572 34.1667 25.8525 33.1872 26.7821C32.3232 27.6072 31.118 27.7117 30.1164 27.0461C29.7257 26.7821 29.489 26.5016 29.2579 26.0065C29.0928 25.6654 29.0818 25.5994 29.0873 25.0603C29.0873 24.5983 29.1093 24.4222 29.2084 24.1747C29.6871 22.97 30.8208 22.3649 32.0755 22.6455Z"
            fill="#0068FF"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1112_3037"
          x="0"
          y="0"
          width="60"
          height="60"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1112_3037"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1112_3037"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1112_3037">
          <rect
            x="8"
            y="4"
            width="44"
            height="44.0002"
            rx="8.8"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </a-layout>
</template>

<script>
import { instance } from "@/api";

export default {
  name: "LayoutPage",
  data() {
    return {};
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      this.$store.commit({
        type: 'toggleLogged',
        value: true
      })
      instance.interceptors.request.use(
        (config) => {
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
  },
  methods: {
    handleLogin() {
      this.$router.push("/login");
    },
    handleClickLogo() {
      this.$router.push("/");
    },
    handleLogout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
      this.$store.commit('toggleLogged')
    },
    handleClickZaloLogo() {
      window.open("https://zalo.me/84962432768", "_blank");
    },
  },
};
</script>
