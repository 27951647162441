<template>
  <div>
    <div
      class="border-b border-solid border-0 border-b-neutral-300 border-b-solid"
    >
      <div class="mx-auto container py-20 lg:px-0 px-16">
        <a-row>
          <a-col class="w-full lg:w-auto">
            <a-row :gutter="8">
              <a-col :span="12">
                <a-dropdown
                  v-model:open="visibleCategory"
                  class="relative w-full lg:w-160 h-40 bg-neutral-100"
                  size="large"
                >
                  <a-button
                    class="flex items-center justify-between"
                    @click.prevent
                  >
                    <span>Ngành</span>
                    <DownOutlined />
                  </a-button>
                  <template #overlay>
                    <a-menu
                      style="background-color: #f2f4f7"
                      @click="handleMenuClick"
                    >
                      <a-menu-item
                        style="cursor: default"
                        disabled
                        class="border-b border-solid border-0 border-neutral-200 mb-4"
                      >
                        <span class="caption-4"> NGÀNH</span>
                      </a-menu-item>
                      <a-menu-item
                        v-for="item in filterCategory"
                        :key="item.value"
                      >
                        <a-checkbox
                          :checked="item.checked"
                          size="lg"
                          class="mr-4 w-24 h-24"
                        />
                        <span class="typography-1">{{ item.label }}</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </a-col>
              <a-col :span="12">
                <a-dropdown
                  v-model:open="visibleDuratime"
                  class="relative w-full lg:w-160 h-40 bg-neutral-100"
                  size="large"
                >
                  <a-button
                    class="flex items-center justify-between"
                    @click.prevent
                  >
                    <span>Thời gian</span>
                    <DownOutlined />
                  </a-button>
                  <template #overlay>
                    <a-menu
                      style="background-color: #f2f4f7"
                      @click="handleMenuClickDuratime"
                    >
                      <a-menu-item
                        style="cursor: default"
                        disabled
                        class="border-b border-solid border-0 border-neutral-200 mb-4"
                      >
                        <span class="caption-4"> THỜI GIAN</span>
                      </a-menu-item>
                      <a-menu-item
                        v-for="item in filterPeriod"
                        :key="item.value"
                      >
                        <a-checkbox
                          :checked="item.checked"
                          class="mr-4 w-24 h-24"
                        />
                        <span class="typography-1">{{ item.label }}</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </a-col>
            </a-row>
          </a-col>
          <a-col
            class="lg:block hidden"
            flex="auto"
          />
          <a-col
            v-if="data.length > 0"
            class="lg:block hidden"
          >
            <div class="flex items-center gap-12">
              <span>Trang</span>
              <div class="bg-neutral-100 py-8 px-8 border border-neutral-300">
                <a-pagination
                  v-model:current="page"
                  simple
                  :total="totalRows"
                  @change="handleChangePage"
                />
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
  <div class="mx-auto container lg:px-0 px-16">
    <div class="mb-24 lg:mb-30 pt-30">
      <template
        v-for="item in data"
        :key="item.name"
      >
        <Item
          :item="item"
          :toggle-modal="toggleModal"
        />
      </template>
    </div>
    <div
      v-if="data.length > 0"
      class="flex items-center pt-24 justify-center mb-24 lg:mb-30 border-0 border-t border-solid border-neutral-300"
    >
      <div class="bg-neutral-100 py-8 px-8 border border-neutral-300">
        <a-pagination
          v-model:current="page"
          simple
          :total="totalRows"
          @change="handleChangePage"
        />
      </div>
    </div>

    <a-modal
      v-model:open="openModal"
      centered
      :closable="false"
      dialog-class="p-0"
      class="p-0"
      destroy-on-close
      :body-style="{
        height: 'calc(100vh - 135px)',
      }"
    >
      <template #title>
        <div
          class="mx-[-24px] bg-neutral-200 p-16 mt-[-20px] mb-[-8px] border-0 border-b border-solid border-neutral-300 flex items-center justify-between"
        >
          <span class="caption-2">{{ itemSelected.industry?.name }}</span>
          <svg
            class="cursor-pointer hover:bg-neutral-100"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="() => toggleModal()"
          >
            <path
              d="M25 7L7 25"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M25 25L7 7"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </template>

      <template #footer>
        <div
          class="p-16  border-0 border-t border-solid bg-neutral-200 border-neutral-300 mx-[-24px] px-16 mt-[-12px] mb-[-20px]"
        >
          <a-button
            class="shadow-none lg:w-160 font-semibold h-36"
            type="primary"
            @click="handleContact"
          >
            Liên hệ
          </a-button>
        </div>
      </template>

      <div
        class="mx-[-24px] px-16 py-8 pb-16 overflow-auto bg-neutral-200 flex flex-col gap-16 lg:gap-24 h-full"
      >
        <div>
          <div class="heading-1 text-neutral-400 mb-8">
            MỨC LƯƠNG (EUR/THÁNG)
          </div>

          <Tag
            v-if="itemSelected.firstYearSalary"
            variant="primary"
          >
            {{ itemSelected.firstYearSalary }} (năm nhất)
          </Tag>
          <Tag
            v-if="itemSelected.secondYearSalary"
            variant="primary"
          >
            {{ itemSelected.secondYearSalary }} (năm hai)
          </Tag>
          <Tag
            v-if="itemSelected.thirdYearSalary"
            variant="primary"
          >
            {{ itemSelected.thirdYearSalary }} (năm ba)
          </Tag>
        </div>
        <div>
          <div class="heading-1 text-neutral-400 mb-8">
            TÀI TRỢ CHO ỨNG VIÊN
          </div>
          <Tag
            v-for="item in itemSelected.entitlement"
            :key="item.describe"
          >
            {{ item.describe }}
          </Tag>
        </div>

        <div>
          <div class="heading-1 text-neutral-400 mb-8">
            YÊU CẦU
          </div>
          <Tag
            v-if="itemSelected.germanRequest !== 'NO'"
          >
            {{ itemSelected.germanRequest }}
          </Tag>
          <Tag
            v-if="
              itemSelected.profession"
          >
            {{ itemSelected.profession.name }}
          </Tag>
          <Tag
            v-if="itemSelected.experience"
          >
            Có bằng cấp và kinh nghiệm
          </Tag>
        </div>

        <a-row :gutter="12">
          <a-col class="max-w-full lg:max-w-[50%] w-full">
            <div class="heading-1 text-neutral-400 mb-8">
              Bang
            </div>
            <Tag fill>
              {{ itemSelected.state }}
            </Tag>
          </a-col>
          <a-col class="max-w-full lg:max-w-[50%] w-full">
            <div class="heading-1 text-neutral-400 mb-8">
              Kỳ bay
            </div>
            <Tag fill>
              {{
                itemSelected.fromDate &&
                  format(new Date(itemSelected.fromDate), "mm/yyyy")
              }}
              -
              {{
                itemSelected.toDate &&
                  format(new Date(itemSelected.toDate), "mm/yyyy")
              }}
            </Tag>
          </a-col>
        </a-row>
        <div>
          <div class="heading-1 text-neutral-400 mb-8">
            HÌNH ẢNH
          </div>
          <div
            v-for="image in itemSelected.gallery"
            :key="image.imageUrl"
            class="bg-white text-center mb-8"
          >
            <a-image
              :preview="false"
              :src="image.imageUrl"
            />
          </div>
        </div>
        <div
          class="p-16 text-start border-0 border-t border-solid bg-neutral-200 border-neutral-300 mt-[-12px] mb-[-20px]"
        >
          <div class="typography-2 mb-8">
            {{ itemSelected.industry?.title }}
          </div>
          <p class="text-neutral-400 typography-3">
            {{ itemSelected.industry?.description }}
          </p>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getCategories, getContracts, getPeriods } from "@/api";
import { DownOutlined } from "@ant-design/icons-vue";
import { format } from "date-fns";

import Item from "./components/item";
import Tag from "./components/tag";

export default {
  name: "HomePage",
  components: {
    Item,
    Tag,
    DownOutlined,
  },

  data: function () {
    return {
      format,
      filterCategory: [],
      filterPeriod: [],
      openModal: false,
      visibleCategory: false,
      visibleDuratime: false,
      itemSelected: null,
      industrySelected: {
        title:'',
        description: ''
      },
      totalRows: 0,
      page: 1,
      filter: {
        limit: 10,
        offset: 0,
        ids: [],
        periods: [],
      },
      data: [],
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
  },
  watch: {
    isLogged(newValue) {
      if (!newValue) {
        this.data = [];
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    fetchContracts() {
      getContracts(this.filter)
        .then((res) => {
          if (res.data.success) {
            this.totalRows = res.data.data.total;
            this.data = res.data.data.contracts;
          }
        })
        .catch(this.handleError401);
    },
    getData() {
      getCategories()
        .then((res) => {
          if (res.data.success) {
            this.filterCategory = res.data.data.industries.map((industry) => ({
              value: industry.id,
              description: industry.description,
              title: industry.title,
              label: industry.name,
              checked: false,
            }));
          }
        })
        .catch(this.handleError401);

      getPeriods()
        .then((res) => {
          if (res.data.success) {
            this.filterPeriod = res.data.data.periods.map((period) => ({
              value: period.value,
              label: period.name,
              checked: false,
            }));
          }
        })
        .catch(this.handleError401);

      this.fetchContracts();
    },
    onFinishFailed: () => {},
    toggleModal(item) {
      if (item) {
        this.itemSelected = item;
      }
      this.openModal = !this.openModal;
    },
    handleMenuClick(e) {
      let ids = []
      this.filterCategory = this.filterCategory.map((item) => {
        if (item.value === e.key) {
          if (!item.checked) {
            ids.push(item.value)
          }
          return {
            ...item,
            checked: !item.checked,
          };
        }
        if (item.checked) {
          ids.push(item.value)
        }
        return item;
      });

      this.filter = {
        ...this.filter,
        ids,
        offset: 0,
      }
      this.page = 1;
      this.fetchContracts()
    },
    handleMenuClickDuratime(e) {
      let periods = []
      this.filterPeriod = this.filterPeriod.map((item) => {
        if (item.value === e.key) {
          if (!item.checked) {
            periods.push(item.value)
          }
          return {
            ...item,
            checked: !item.checked,
          };
        }
        if (item.checked) {
          periods.push(item.value)
        }
        return item;
      });
      this.filter = {
        ...this.filter,
        periods,
        offset: 0,
      }
      this.page = 1;
      this.fetchContracts()
    },
    handleChangePage(page) {
      this.page = page;
      this.filter = {
        ...this.filter,
        limit: 10,
        offset: (page - 1) * 10,
      };

      this.fetchContracts();
    },
    handleError401(error) {
      if (error && error.response.status === 401) {
        this.$router.replace('/login')
      }
    },
    handleContact() {}
  },
};
</script>
