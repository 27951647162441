<template>
  <div class="container mx-auto ">
    <div class="mt-64">
      <a-card
        style="width: 380px"
        class="mx-auto bg-neutral-100"
        head-style="border-color: #E3E6EA"
      >
        <template #title>
          <span class="text-28">Đăng nhập</span>
        </template>
        <a-form
          :model="formState"
          name="basic"
          autocomplete="off"
          layout="vertical"
          @finish="onFinish"
          @finish-failed="onFinishFailed"
        >
          <a-form-item
            name="username"
            :rules="[{ required: true, message: 'Vui lòng nhập tài khoản' }]"
          >
            <template #label>
              <span class="font-semibold text-neutral-400">TÀI KHOẢN</span>
            </template>
            <a-input
              v-model:value="formState.username"
              placeholder="Email của bạn"
              size="large"
            />
          </a-form-item>

          <a-form-item
            name="password"
            :rules="[{ required: true, message: 'Vui lòng nhập mật khẩu' }]"
          >
            <template #label>
              <span class="font-semibold text-neutral-400">MẬT KHẨU</span>
            </template>
            <a-input-password
              v-model:value="formState.password"
              placeholder="Mật khẩu của bạn"
              size="large"
            />
          </a-form-item>


          <a-form-item>
            <a-button
              :loading="loading"
              class="shadow-none w-full h-36 font-semibold"
              type="primary"
              html-type="submit"
            >
              Đăng nhập
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
  </div>
</template>

<script>
import { login, instance } from '@/api';
import { notification } from 'ant-design-vue';

export default {
  name: 'LoginPage',
  data: function () {
    return {
      loading: false,
      formState: {
        username: '',
        password: '',
      }
    };
  },
  methods: {
    onFinish() {
      this.loading = true;
      const data = {
        username: this.formState.username,
        password: this.formState.password
      }
      login(data).then((res) => {
        if (res.data.success) {
          localStorage.setItem('token', res.data.data.accessToken)
          instance.interceptors.request.use(
            config => {
              config.headers['Authorization'] = `Bearer ${res.data.data.accessToken}`;
              return config;
            },
            error => {
              return Promise.reject(error);
            }
            );
            this.$router.push('/')
            this.$store.commit('toggleLogged')
        }
      }).catch((er) => {
        notification.error({
          message: 'Thất bại',
          description:
            'Sai tài khoản/mật khẩu',
        });
      }).finally(( ) => {
        this.loading = false
      })
    },
    onFinishFailed() {
    }
  }
}
</script>